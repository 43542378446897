import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume, faBars } from '@fortawesome/free-solid-svg-icons';
import m3mLogo from '../assets/m3mLogo.webp';
import './css/Navbar.css';

const Navbar = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <div className="navbar">
      <img src={m3mLogo} alt="logo" className="logo-small" />
      <nav className="navbar-navigation">
        <Link to="overview" smooth={true} duration={800} className="nav-link">OVERVIEW</Link>
        <Link to="pricing-boxes" smooth={true} duration={800} className="nav-link">PRICING</Link>
        <Link to="gallery-section" smooth={true} duration={800} className="nav-link">GALLERY</Link>
        <Link to="floorPlan" smooth={true} duration={800} className="nav-link">FLOOR PLAN</Link>
        <Link to="highlights" smooth={true} duration={800} className="nav-link">HIGHLIGHTS</Link>
        <Link to="amenities" smooth={true} duration={800} className="nav-link">AMENITIES</Link>
        <Link to="location" smooth={true} duration={800} className="nav-link">LOCATION</Link>
      </nav>
      <a href="tel:9007077676" className='navbar-icon'>
        <FontAwesomeIcon icon={faPhoneVolume} className="fa-2x fa-bounce" />
      </a>
      {/* <div className="navbar-hamburger" onClick={toggleNav}>
        <FontAwesomeIcon icon={faBars} className="fa-2x" />
      </div> */}
    </div>
  );
}

export default Navbar;
