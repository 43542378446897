import React, { useState } from 'react';
import image1 from '../assets/HIghlightImage.webp';
import './css/Highlights.css';
import Modal from './Modal';

const Highlights = () => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  
  return (
  <>
     <div className='highlights'>
        <div className='highlights-section'>

          <div className="highlights-section-image-section">
            <img src={image1} alt="image1" />
          </div>

          <div className="highlights-section-text-section">

            <h2 className="highlights-section-text-section-main-text">Highlights</h2>

            <ul>
              <li>Land Area : </li>
              <li>Project Rera No. : </li>
              <li>Possession Date : </li>
              <li>Total no. of tower : </li>
              <li>Architecture by : </li>
              <li>Interiors by : </li>
              <li>Construction by : </li>
              <li>Club house : </li>
              <li>Payment Plan : </li>
            </ul>

            <br />

            <button className="highlights-section-button" onClick={toggleModal}>Get Details →</button>

          </div>
          <Modal isVisible={isModalVisible} onClose={toggleModal} />
        </div>
        
      </div>
  </>
  );
}

export default Highlights;
