
import './App.css';
import Navbar from './golfcomponents/Navbar';
import WelcomeImage from './golfcomponents/WelcomeImage';
import Overview from './golfcomponents/Overview';
import Pricing from './golfcomponents/Pricing';
import Gallery from './golfcomponents/Gallery';
import Divider from './golfcomponents/Divider';
import FloorPlan from './golfcomponents/FloorPlan';
import Highlights from './golfcomponents/Highlights';
import Amenities from './golfcomponents/Amenities';
import Location from './golfcomponents/Location';
import Disclaimer from './golfcomponents/Disclaimer';
import StickyFooter from './golfcomponents/StickyFooter';

function App() {
  return (
    <div className="App">
      <Navbar />
      <StickyFooter/>
      <WelcomeImage/>
      <Overview/>
      <Pricing/>
      <Divider/>
      <Gallery/>
      <FloorPlan/>
      <Highlights/>
      <Amenities/>
      <Location/>
      <Disclaimer/>
    </div>
  );
}

export default App;
