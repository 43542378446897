import React, { useState } from 'react';
import './css/Modal.css';

const Modal = ({ isVisible, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  if (!isVisible) return null;

  const sendMessage = async (e) => {
    e.preventDefault();

    const apiUrl = 'https://cms.propertystation.in/api/v2/ppc/lead';
    const apiUserPublicKey = 'AqQnvCzVWA1yUyHJxbZCmeFZfo6Ni4KIPA7XpcPPbmL3dFrQX7NSFKJWroUCPUeWOLsZvktjSA==';
    const apiUserSecretKey = 'lNCEgw6NLlE5UUq6ojgSPe+2Mlndg8Xv+uUu2Nwk';

    const apiHeaders = {
      'public-key': apiUserPublicKey,
      'secret-key': apiUserSecretKey,
      'Content-Type': 'application/json',
    };

    const postData = {
      name: formData.name,
      email: formData.email,
      mobile_no: formData.phone,
      message: formData.message,
      source_id: 54,
      builder_id: '4',
      project_id: '396',
    };

    const encodedData = JSON.stringify(postData);

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: apiHeaders,
        body: encodedData,
      });

      if (response.ok) {
        alert('Message sent successfully!');
        onClose();
      } else {
        const result = await response.text();
        alert('Error sending message: ' + result);
      }
    } catch (error) {
      alert('Error: ' + error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close-button" onClick={onClose}>&times;</span>
        <form className="modal-form" onSubmit={sendMessage}>
          <div className="row">
            <h3>Send A Message!</h3>
            <div className="col-lg-12 col-sm-12">
              <div className="form-group">
                <i className="bx bx-user"></i>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Your Name*"
                  required
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-12 col-sm-12">
              <div className="form-group">
                <i className="bx bx-envelope"></i>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email*"
                  required
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-12 col-sm-12">
              <div className="form-group">
                <i className="bx bx-phone"></i>
                <input
                  type="tel"
                  name="phone"
                  className="form-control"
                  placeholder="Your Phone"
                  required
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-12 col-sm-12">
              <div className="form-group">
                <i className="bx bx-file"></i>
                <input
                  type="text"
                  name="message"
                  className="form-control"
                  placeholder="Your Message"
                  required
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-12 col-sm-12">
              <div className="form-group">
                <input
                  type="submit"
                  className="form-control"
                  style={{ backgroundColor: '#1c3863', color: '#ffffff' }}
                />
                <div className="help-block with-errors"></div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Modal;
