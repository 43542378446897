import React, { useState } from "react";
import F1 from "../assets/FloorPanel1.webp";
import F2 from "../assets/FloorPanel2.webp";
import F3 from "../assets/FloorPanel3.webp";
import M1 from "../assets/FloorPanel4.webp";
import "./css/FloorPlan.css";
import Modal from "./Modal";

const FloorPlan = () => {
  const [selectedType, setSelectedType] = useState("type1");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const floorPlanImages = {
    type1: F1,
    type2: F2,
    type3: F3,
    masterPlan: M1,
  };

  return (
    <>
      <div className="floorPlan">
        <h2 className="floorPlan-main-h2">Floor Plans & Master Plan</h2>
        <hr />
        <div className="floorPlan-content">
          <div className="floorPlan-type">

            <div className="floorPlan-types">

              <div
                className={`floorPlan-type1 ${
                  selectedType === "type1" ? "active" : ""
                }`}
                onClick={() => setSelectedType("type1")}
              >
                3.5 BHK
              </div>

              <div
                className={`floorPlan-type2 ${
                  selectedType === "type2" ? "active" : ""
                }`}
                onClick={() => setSelectedType("type2")}
              >
                3.5 BHK + SR
              </div>

              <div
                className={`floorPlan-type3 ${
                  selectedType === "type3" ? "active" : ""
                }`}
                onClick={() => setSelectedType("type3")}
              >
                4 BHK
              </div>

              <div
                className={`floorPlan-type4 ${
                  selectedType === "masterPlan" ? "active" : ""
                }`}
                onClick={() => setSelectedType("masterPlan")}
              >
                Master Plan
              </div>

            </div>

            <div className="floorPlan-type-button">
              <button onClick={toggleModal}>Get Details →</button>
            </div>

          </div>

          <div className="floorPlan-images">
            <img src={floorPlanImages[selectedType]} alt="Floor Plan" />
          </div>

          <Modal isVisible={isModalVisible} onClose={toggleModal} />
        </div>
      </div>
    </>
  );
};

export default FloorPlan;
