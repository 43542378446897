import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './css/Divider.css';
import Modal from './Modal';

const Divider = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handlePhoneCall = () => {
    window.location.href = 'tel:9007077676';
  };

  const handleWhatsAppMessage = () => {
    window.location.href = 'https://wa.me/+919007077676?text=Hi! I am interested in M3M Golf Hills, kindly share more details';
  };

  return (
    <div className='divider-section'>
      <div className='divider-section-heading'>
        <p>Let's have a quick discussion with us !</p>
      </div>
      <div className='divider-section-icons'>
        <div className='divider-section-icons-whatsapp'>
          <FontAwesomeIcon icon={faWhatsapp} onClick={handleWhatsAppMessage} beatFade style={{ color: "#ffffff", fontSize: "2em" }} />
        </div>
        <div className='divider-section-icons-phone'>
          <FontAwesomeIcon icon={faPhoneVolume} onClick={handlePhoneCall} beatFade style={{ color: "#ffffff", fontSize: "2em" }} />
        </div>
        <Modal isVisible={isModalVisible} onClose={toggleModal} />
      </div>
    </div>
  );
};

export default Divider;
