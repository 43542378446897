import './css/Disclaimer.css';

const Disclaimer = () => {
    return (
        <>
            <div className='disclaimer-section'>
                <div className='disclaimer-section-heading'>
                    <h5>Marketed By - Authorised Channel Partner | Haryana RERA Regd NO. RC|REA|HARERA|GGM|2019|279</h5>
                </div>
                <div className='disclaimer-section-paragraph'>
                    <p>This is not the official website of developer & property, it belongs to authorised channel partner for information purposes only. All rights for logo & images are reserved to the developer. Thank you for visiting our website.|| All Rights Reserved CopyRight © 2020</p>
                </div>
            </div>
        </>
    )
}

export default Disclaimer