import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './css/StickyFooter.css';

const StickyFooter = () => {
  const handleCallClick = () => {
    window.location.href = 'tel:9007077676';
  };

  const handleWhatsAppClick = () => {
    window.location.href = 'https://wa.me/+919007077676?text=Hi! I am interested in M3M Golf Hills, kindly share more details';
  };

  return (
    <div className='sticky-footer'>
      <div className='footer-icons'>

        <div className='footer-icon' onClick={handleWhatsAppClick}>
          <FontAwesomeIcon icon={faWhatsapp} beatFade style={{ color: "#ffffff", fontSize: "2em" }} />
        </div>

           

        <div className='footer-icon' onClick={handleCallClick}>
          <FontAwesomeIcon icon={faPhoneVolume} beatFade style={{ color: "#ffffff", fontSize: "2em" }} />
        </div>

      </div>
    </div>
  );
};

export default StickyFooter;
