import React, { useState } from "react";
import "./css/Pricing.css";
import Modal from "./Modal";

const Pricing = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div className="pricing-boxes">
      <div className="pricing-boxes-heading">
        <h3 className="ititle">
          <span className="suptitle">Pricing</span>
          <span className="luxury">LIVE IT UP LARGE WITH THE <br /> 0.5 BHK EXTRA SPACE</span>
        </h3>
      </div>
      <div className="pricing-box">
        <div className="pricing-box-box">
          <h2>Type I</h2>
          <hr />
          <h2> 3.5 BHK</h2>
          <h3> 1902 sq.ft</h3>
          <hr />
          <h2>Price</h2>
          <h3>₹ 2.99 Crs* Onwards</h3>
          <button className="pricing-box-box-button" onClick={toggleModal}>
            Get Details →
          </button>
        </div>
        <div className="pricing-box-box">
          <h2>Type II</h2>
          <hr />
          <h2>3.5 BHK + SR </h2>
          <h3>  2090 sq.ft</h3>     
          <hr />
          <h2>Price</h2>
          <h3>₹ 3.29 Crs* Onwards</h3>
          <button className="pricing-box-box-button" onClick={toggleModal}>
            Get Details →
          </button>
        </div>
        <div className="pricing-box-box">
          <h2>Type III</h2>
          <hr />
          <h2> 4.5 BHK + SR </h2>
          <h3>  2630 sq.ft</h3>
          <hr />
          <h2>Price</h2>
          <h3>₹ 4.14 Crs* Onwards</h3>
          <button className="pricing-box-box-button" onClick={toggleModal}>
            Get Details →
          </button>
        </div>
        <Modal isVisible={isModalVisible} onClose={toggleModal} />
      </div>
    </div>
  );
};

export default Pricing;
