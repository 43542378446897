import { useState } from 'react';
import location from '../assets/Location.webp';
import './css/Location.css';
import Modal from './Modal';

const Location = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  return (
    <>
      <div className='location'>
        <div className='location-section'>
          <div className="location-section-text-section">
            <h2 className='location-section-text-section-main-text'>Location</h2>
            <ul>
              <li> 5 minutes to NH-8*.</li>
              <li>10 minutes to KMP Expressway*.</li>
              <li>15 minutes to Dwarka Expressway*.</li>
              <li>5 Minutes to Karma Lakelands*.</li>
              <li>15 Minutes to Jungle Safari & Trails*.</li>
            </ul>
            <br />
            <button className="location-section-button" onClick={toggleModal}>Get Details →</button>
          </div>
          <div className="location-section-image-section">
            <img src={location} alt="image1" />
          </div>
          <Modal isVisible={isModalVisible} onClose={toggleModal} />
        </div>
      </div>
    </>
  )

}

export default Location