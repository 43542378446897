 import React from 'react';
import gymIcon from '../assets/gym-icon.png';
import horseIcon from '../assets/horse-icon.png';
import './css/Amenities.css';

const Amenities = () => {
  return (
    <>
      <div className='amenities'>
        <div className='amenities-heading'>
          <h2>Luxury Amenities</h2>
          <hr/>
        </div>
        <div className='amenities-features'>
          <div className='feature-item1'>
          <img src={gymIcon} alt="Placeholder" />
            <h4>EQUPPED GYM</h4>
          </div>
          <div className='feature-item2'>
          <img width="100" height="100" src="https://img.icons8.com/ios/100/wallmount-camera.png" alt="wallmount-camera"/>
            <h4>GATED COMMUNITY</h4>
          </div>
          <div className='feature-item3'>
          <img width="100" height="100" src="https://img.icons8.com/ios/100/FFFFFF/sunbathe.png" alt="sunbathe"/><h4>LUXURY CLUB HOUSE</h4>
          </div>
          <div className='feature-item4'>
          <img width="100" height="100" src="https://img.icons8.com/ios-filled/100/1A1A1A/lap-pool.png" alt="lap-pool"/>
            <h4>SWIMMING POOL</h4>
          </div>
          <div className='feature-item5'>
          <img width="64" height="64" src="https://img.icons8.com/pastel-glyph/64/1A1A1A/air-conditioner--v3.png" alt="air-conditioner--v3"/>
            <h4> AC LOBBIES</h4>
          </div>
          <div className='feature-item6'>
          <img width="100" height="100" src="https://img.icons8.com/ios/100/FFFFFF/golf.png" alt="golf"/>
            <h4>GOLF COURSE</h4>
          </div>
          <div className='feature-item7'>
          <img width="96" height="96" src="https://img.icons8.com/parakeet-line/96/1A1A1A/deciduous-tree.png" alt="deciduous-tree"/>
            <h4>LANDSCAPED GREENS</h4>
          </div>
          <div className='feature-item8'>
            <img src={horseIcon} alt="Placeholder" />
            <h4>DEDICATED KIDS ZONE</h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default Amenities;
