import React from 'react';
import Belowheader from '../assets/Home Banner.webp';
import './css/WelcomeImage.css';

const WelcomeImage = () => {
  return (
    <div className="welcome-section">
      <img src={Belowheader} alt="Placeholder" />
    </div>
  );
}

export default WelcomeImage;
