import React, { useState } from "react";
import image1 from "../assets/Overview Image.webp";
import image2 from "../assets/Overview2.webp";
import "./css/Overview.css";
import Modal from "./Modal";

const Overview = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div className="overview">
      <h2>
        Experience Finest Living <br />
        With Golf Themed Luxury Residences
      </h2>
      <hr />

      <div className="overview-section">
        <div className="text-section">
          {/* <h2 className="overview-section-text-section-main-text">
            M3M Golf Hills offer life that is truly global and world class!
          </h2> */}

          <p>
             M3M Golf Hills is a true trendsetter of golf inspired living and
            offers a holistic living experience for a <em>lifestyle</em>  that is a class
            apart. <br /> With an array of amenities and facilities overlooking the
            Aravalli’s, M3M Golf Hills offer life that is truly global and world
            class besides being an address of stature. <br />
            With a neighborhood
            connected to all major hotspots, you are always close to all that
            you need in the vicinity.
          </p>

          <button className="overview-content-button" onClick={toggleModal}>
            Get Details →
          </button>
          <Modal isVisible={isModalVisible} onClose={toggleModal} />
        </div>

        <div className="image-section">
          <img src={image1} alt="image1" />
        </div>
      </div>

      <div
        className="overview-section-two-main"
        style={{ backgroundImage: `url(${image2})` }}
      >
        <div className="overview-section-two-box">
          <div className="overview-section-two-heading">
            <h1>M3M Golf Hills</h1>
          </div>
          <div className="overview-section-table">
            <div className="overview-section-table-p1">
              {" "}
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/external-anggara-glyph-anggara-putra/64/FFFFFF/external-golf-ball-sports-anggara-glyph-anggara-putra.png"
                alt="external-golf-ball-sports-anggara-glyph-anggara-putra"
              />
              <p>Location - Sector 79, Gurgaon</p>
            </div>
            <div className="overview-section-table-p1">
              {" "}
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/external-anggara-glyph-anggara-putra/64/FFFFFF/external-golf-ball-sports-anggara-glyph-anggara-putra.png"
                alt="external-golf-ball-sports-anggara-glyph-anggara-putra"
              />
              <p>Type - 2.5, 3.5 & 4.5 BHK</p>
            </div>
            <div className="overview-section-table-p1">
              {" "}
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/external-anggara-glyph-anggara-putra/64/FFFFFF/external-golf-ball-sports-anggara-glyph-anggara-putra.png"
                alt="external-golf-ball-sports-anggara-glyph-anggara-putra"
              />
              <p> Price Starts - ₹ 2.2 Crs.* Onwards</p>
            </div>
            <div className="overview-section-table-p1">
              {" "}
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/external-anggara-glyph-anggara-putra/64/FFFFFF/external-golf-ball-sports-anggara-glyph-anggara-putra.png"
                alt="external-golf-ball-sports-anggara-glyph-anggara-putra"
              />
              <p>Payment Plan - 20:80</p>
            </div>
          </div>
          {/* <div className="overview-section-buttons">
            <button className="call-button" onClick={toggleModal}>
              Call →
            </button>
            <button className="details-button" onClick={toggleModal}>
              Get Details →
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Overview;
